<script setup lang="ts">
import { BanIcon, ExclamationIcon, QuestionMarkCircleIcon } from "@heroicons/vue/outline";
import { BadgeCheckIcon } from "@heroicons/vue/solid";
import type { PropType } from "vue";
import ProfileHandle from "~/components/ProfileHandle.vue";
import { getUrlForCollection } from "~/utilities/url-slugs";

const props = defineProps({
  collection: Object as PropType<any>,
  unlinked: { type: Boolean as PropType<boolean>, default: () => false },
});
const { collection, unlinked } = toRefs(props);

const name = computed(() => {
  return collection.value.name || 'Unnamed';
});
</script>

<template>
  <div
    class="shadow-card hover:shadow-card-hover group relative flex flex-col overflow-hidden rounded-lg bg-white transition duration-300 ease-in-out dark:bg-black"
  >
    <div class="aspect-h-1 aspect-w-1 relative overflow-hidden">
      <div v-if="!collection" class="flex items-center justify-center">
        <div class="h-full w-full animate-pulse bg-neutral-100 dark:bg-neutral-800"></div>
      </div>
      <div v-else-if="collection.creator?.verification_state === 2" class="flex items-center justify-center">
        <ExclamationIcon class="h-1/2 w-1/2 text-red-600" />
      </div>
      <div v-else-if="collection.blocked_content" class="flex items-center justify-center">
        <BanIcon class="h-1/2 w-1/2 text-neutral-600 dark:text-neutral-300" />
      </div>
      <img
        v-else-if="collection.thumbnail_uri"
        :alt="`${name} image`"
        :src="collection.thumbnail_uri"
        loading="lazy"
        :class="[collection.sensitive_content ? 'blur-lg' : '']"
        class="h-full w-full object-cover object-center transition duration-300 ease-out betterhover:group-hover:scale-110"
      />
      <div v-else class="flex items-center justify-center">
        <QuestionMarkCircleIcon class="h-1/2 w-1/2 text-neutral-400 dark:text-neutral-500" />
      </div>
      <NuxtLink
        v-if="collection"
        :to="unlinked ? undefined : getUrlForCollection(collection)"
        class="absolute inset-0 z-20 flex h-full w-full flex-col items-center justify-start bg-transparent"
      >
        <div class="flex-1"></div>
        <div class="flex w-full px-4 py-4 opacity-0"></div>
      </NuxtLink>
    </div>
    <div class="flex flex-1 flex-col justify-between gap-4 p-4">
      <div v-if="!collection">
        <div class="h-6 w-3/4 rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
      </div>
      <template v-else>
        <div class="flex items-center gap-1">
          <span class="text-md truncate font-bold text-black dark:text-white">{{ name }}</span>
          <BadgeCheckIcon v-if="collection.creator?.verification_state === 1" class="h-5 w-5 text-emerald-600" />
        </div>
        <div class="flex flex-1 flex-row items-start justify-between gap-2">
          <ProfileHandle :profile="collection.creator" />
          <div v-if="collection.unminted_nft_count" class="flex flex-col items-start">
            <span class="text-xs font-semibold text-neutral-500 dark:text-neutral-400">Mint Active</span
            ><span class="text-sm font-semibold text-black dark:text-white"
              >{{ collection.unminted_nft_count }} left</span
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
